import styled from '@emotion/styled';
import { useAppState, useSetAppState } from '../contexts/AppStateContext';
import { searchCourse } from '../utils/api/search-course/nagoya-city';
import { filterCourses, getCourseColors } from '../utils/nagoya-city';
import { DateTime } from 'luxon';
import { ERROR_STATUS, SearchError } from '../utils/error';
import UpdateIcon from '@mui/icons-material/Update';
import { debugLog } from '../utils/logeer';

interface Props {
  arrivalPoineName: string;
  arrivalPointPosition: string;
  busStopCode: string;
  depDate: DateTime;
}

const ReSearch: React.FC<Props> = (props: Props) => {
  const state = useAppState();
  /** アプリケーション全体の状態を更新する関数。 */
  const setState = useSetAppState();
  const execReSearch = async () => {
    /** ローディング状態にする */
    setState({
      ...state,
      isLoading: true,
    });
    /** 現在日時。 */
    const currentTime = DateTime.now().set({
      second: 0,
      millisecond: 0,
    });
    /** 溜め込んでいる経路の中からまだ間に合う経路がないか確認する */
    const enoughCourses = filterCourses(
      state.courses,
      props.busStopCode,
      currentTime
    );
    debugLog(
      `現在保持している経路のうち、間に合う経路が${enoughCourses.length}件あります`
    );
    /** 間に合う経路が１件以上残っている場合 */
    if (enoughCourses.length > 0) {
      debugLog('間に合う経路があるため再検索はしません');
      const colors = getCourseColors(enoughCourses[0], props.busStopCode);
      /** 新経路情報に切り替える */
      setState({
        ...state,
        isLoading: false,
        courses: enoughCourses,
        color: colors,
      });
      return;
    }
    /** 間に合う経路が残ってない場合には再検索をかける */
    debugLog('間に合う経路がないため再検索をします');
    /** 経路検索を実行する */
    const courses = await searchCourse(
      currentTime,
      props.arrivalPointPosition,
      props.busStopCode,
      props.arrivalPoineName
    );
    // 経路がない場合。
    if (courses.length === 0) {
      throw new SearchError(ERROR_STATUS.NO_ROUTE);
    }
    const colors = getCourseColors(courses[0], props.busStopCode);
    setState({
      ...state,
      isLoading: false,
      courses: courses,
      color: colors,
    });
  };

  const StyledButton = styled('button')({
    fontSize: '15px',
    marginTop: '10px',
    background: '#ffffff',
    borderRadius: '5px',
    border: '1px solid #ffffff',
    padding: '8px',
  });

  return (
    <StyledButton onClick={execReSearch}>
      <UpdateIcon sx={{ mb: -0.8, mr: 0.5 }} />
      <span
        style={{
          fontSize: '14px',
        }}
      >
        再検索する
      </span>
    </StyledButton>
  );
};

export default ReSearch;
