/**
 * デバッグ用（verbose-level）のログを出力する。
 * @param message 出力したい内容。
 */
export const debugLog = (message: unknown) => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  // 開発モードの場合のみデバッグログを表示する。
  if (isDevelopment) {
    console.debug(message);
  }
};
