import React from 'react';
import { Grid } from '@mui/material';

/**
 * 404ページ
 */
const NotFound: React.FC = () => {
  return (
    <Grid container style={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        ページが見つかりませんでした
      </Grid>
    </Grid>
  );
};

export default NotFound;
