import { AppColors, initAppColors } from '../../contexts/AppStateContext';
import { Course, Line } from '../../types/mixway-api/extreme';
import { getBusSystemNumber } from '../api/mixway-utils';

/** 高蔵寺駅の乗り場・系統ごとのカラーリング一覧 */
const ColorTypeSet = {
  north_1: {
    header: '#FF0000',
    arrow: '#EBF0F0',
    font: '#FFFFFF',
  },
  north_2: {
    header: '#FFFE41',
    arrow: '#EBF0F0',
    font: '#333333',
  },
  north_3: {
    header: '#00B050',
    arrow: '#EBF0F0',
    font: '#FFFFFF',
  },
  north_4: {
    header: '#0000FF',
    arrow: '#EBF0F0',
    font: '#FFFFFF',
  },
  north_5: {
    header: '#FFC000',
    arrow: '#EBF0F0',
    font: '#333333',
  },
  north_8: {
    header: '#7030A0',
    arrow: '#EBF0F0',
    font: '#FFFFFF',
  },
  south_1: {
    header: '#FF0000',
    arrow: '#EBF0F0',
    font: '#FFFFFF',
  },
  south_2_kasugai_citybus_northeast: {
    header: '#F171AB',
    arrow: '#EBF0F0',
    font: '#333333',
  },
  south_2_kasugai_citybus_southeast: {
    header: '#F9DE25',
    arrow: '#EBF0F0',
    font: '#333333',
  },
  south_3_yutorito_line: {
    header: '#9900FF',
    arrow: '#EBF0F0',
    font: '#FFFFFF',
  },
  south_3_citybus: {
    header: '#E60000',
    arrow: '#EBF0F0',
    font: '#FFFFFF',
  },
};

export const getCourseColors = (
  course: Course | null,
  busStopCode: string
): AppColors => {
  if (course === null || busStopCode === null) {
    return initAppColors;
  }
  /** 最初の区間を取得する */
  const firstLine = course.Route.Line[0];
  /** 最初の区間の名称。 */
  const firstLineName = firstLine.Name;
  /** のりば番号。 */
  const poleNumber = firstLine.DepartureState.no;

  // 高蔵寺駅北口バス停の場合。
  if (isNorthBusPole(busStopCode)) {
    return getNorthColorType(poleNumber);
  } else {
    // 高蔵寺駅南口バス停の場合。
    return getSouthColorType(firstLineName);
  }
};

/**
 * 高蔵寺駅北口のバスのりば（番号）に応じたカラーリングを取得する。
 * @param poleNumber バスのりば（番号。
 * @returns 高蔵寺駅北口のバスのりば（番号）に応じたカラーリング。
 */
export const getNorthColorType = (poleNumber: string) => {
  switch (poleNumber) {
    case '1':
      return ColorTypeSet.north_1;
    case '2':
      return ColorTypeSet.north_2;
    case '3':
      return ColorTypeSet.north_3;
    case '4':
      return ColorTypeSet.north_4;
    case '5':
      return ColorTypeSet.north_5;
    case '8':
      return ColorTypeSet.north_8;
    default:
      return initAppColors;
  }
};

/**
 * 高蔵寺駅南口のバスのりば（路線）に応じたカラーリングを取得する。
 * @param firstLineName 経路の最初の区間の名称。
 * @returns 高蔵寺駅南口のバスのりば（路線）に応じたカラーリング。
 */
export const getSouthColorType = (firstLineName: string) => {
  // 高蔵寺駅南口
  if (isNorthEastLine(firstLineName)) {
    return ColorTypeSet.south_2_kasugai_citybus_northeast;
  }
  if (isSouthEastLine(firstLineName)) {
    return ColorTypeSet.south_2_kasugai_citybus_southeast;
  }
  if (isYutoritoLine(firstLineName)) {
    return ColorTypeSet.south_3_yutorito_line;
  }
  if (isNagoyaCityBus(firstLineName)) {
    return ColorTypeSet.south_3_citybus;
  }
  return ColorTypeSet.south_1;
};

/**
 * 高蔵寺駅北口のバスのりばを出発する駅コードかを判定する。
 * @param stationCode 駅コード。
 * @returns 高蔵寺駅北口のバスのりばを出発する駅コードかを判定した結果。
 */
export const isNorthBusPole = (stationCode: string) => {
  return stationCode === '233446';
};

/**
 * 路線名が「かすがいシティバス※東北部線」であるか判定する。
 * @param lineName 路線名。
 * @returns 路線名が「かすがいシティバス※東北部線」であるか判定した結果。
 */
export const isNorthEastLine = (lineName: string) => {
  return lineName.includes('かすがいシティバス※東北部線');
};

/**
 * 路線名が「かすがいシティバス※東南部線」であるか判定する。
 * @param lineName 路線名。
 * @returns 路線名が「かすがいシティバス※東南部線」であるか判定した結果。
 */
export const isSouthEastLine = (lineName: string) => {
  return lineName.includes('かすがいシティバス※東南部線');
};

/**
 * 路線名が「ゆとりーとライン」であるか判定する。
 * @param lineName 路線名。
 * @returns 路線名が「ゆとりーとライン」であるか判定した結果。
 */
export const isYutoritoLine = (lineName: string) => {
  return lineName.includes('ゆとりーとライン');
};

/**
 * 路線名が「名古屋市営バス」であるか判定する。
 * @param lineName 路線名。
 * @returns 路線名が「名古屋市営バス」であるか判定した結果。
 */
export const isNagoyaCityBus = (lineName: string) => {
  return lineName.includes('名古屋市営バス');
};
/** バスの路線名を返す */
export const getBusLineName = (line: Line | null) => {
  if (line === null) {
    return '';
  }
  const corporation = line.Corporation?.Name;
  const typicalName = getBusSystemNumber(line.TypicalName || '');

  if (corporation === '愛知県春日井市') {
    return getBusSystemNumber(line.TypicalName || '');
  } else if (
    corporation === '名古屋市営バス' &&
    typicalName.includes('ゆとりーとライン')
  ) {
    return 'ゆとりーとライン';
  } else {
    return corporation;
  }
};
