import axios from 'axios';
import { Course } from '../../types/mixway-api/extreme';
import { ERROR_STATUS, SearchError } from '../error';
import { Params as SearchCourseParams } from './search-course/params';

enum APIPath {
  StationLight = '/v1/json/station/light',
  Extreme = '/v1/json/search/course/extreme',
}

axios.defaults.baseURL = 'https://mixway.ekispert.jp';
axios.defaults.timeout = 7000;

// リクエスト時の共通処理。
axios.interceptors.request.use((request) => {
  request.params = {
    ...request.params,
    // keyパラメーター（mixway APIのキー）を設定する。
    key: process.env['REACT_APP_MIXWAY_API_KEY'] || '',
  };
  return request;
});
// レスポンスを受け取ったときの共通処理。
axios.interceptors.response.use(
  (response) => response,
  // ステータスコード200以外が返却された場合。
  (error) => {
    // ステータスコードに応じて処理を切り替えられるようにしているが、
    // 現状は常にmixway APIのエラーである例外を投げるようにしている。
    switch (error.response?.status) {
      default:
        throw Error(ERROR_STATUS.MIXWAY_API_ERROR);
    }
  }
);

/** インクリメンタルサーチ */
export const getStationLight = async (name: string) => {
  const params = {
    name: name,
  };
  const response = await axios.get(APIPath.StationLight, { params: params });
  return response.data.ResultSet;
};

/** 経路検索 */
export const getSearchCourse = async (
  params: SearchCourseParams
): Promise<Course[]> => {
  const response = await axios.get(APIPath.Extreme, { params: params });
  if (!response.data.ResultSet.Course) {
    throw new SearchError(ERROR_STATUS.NO_ROUTE);
  }
  return response.data.ResultSet.Course;
};
