import React from 'react';
import { Container, styled } from '@mui/material';
import kozojiMapImage from '../../../images/map_kozoji_station.png';

/**
 * バス停位置画像（マップ）のコンポーネント。
 */
const Map: React.FC = () => {
  /** 画像用のスタイル */
  const MapImageStyle = styled('img')({
    width: '90%',
  });

  /** 現状乗り場毎の画像はなく、固定の画像を表示する */
  const mapImageSrc = (() => {
    return kozojiMapImage;
  })();

  return (
    <div style={{ margin: 20, textAlign: 'center' }}>
      <Container>
        <MapImageStyle src={mapImageSrc} alt="バス停マップ" />
      </Container>
    </div>
  );
};

export default Map;
