import { DateTime } from 'luxon';

/** mixway APIの経路検索のパラメーターのクラス。 */
export class Params {
  /** 探索する経路に利用する交通機関。 */
  mode: string;
  /** 発着地。 */
  viaList: string;
  /** 探索日付。 */
  date: string;
  /** 探索時刻。 */
  time: string;
  /** 探索種別。 */
  searchType: string;
  /** 経路の最大回答数。 */
  answerCount: number;
  /** 経路の最大探索数。 */
  searchCount: number;
  /** 固定会社名（経路で利用する交通事業者）。 */
  corporationBind: string;
  /** ポール位置情報を取得する */
  addPole: boolean;
  /** 路線バスのバス停まで徒歩で移動する距離の上限 */
  maxWalkDistanceLocalBus: number;

  /**
   * コンストラクタ。
   * @param depCode 出発駅コード。
   * @param arrCode 到着駅コード（緯度経度）。
   * @param date 出発日時。
   * @param corporations 経路検索の対象とする交通事業者の指定。
   */
  constructor(
    depCode: string,
    arrCode: string,
    date: DateTime,
    corporations: string
  ) {
    this.mode = 'public';
    this.viaList = `${depCode}:${arrCode}`;
    this.date = date.toFormat('yyyyMMdd');
    this.time = date.toFormat('HHmm');
    this.searchType = 'departure';
    this.answerCount = 10;
    this.searchCount = 10;
    this.corporationBind = corporations;
    this.addPole = true;
    this.maxWalkDistanceLocalBus = 2000;
  }
}
