import { useLocation } from 'react-router';
import { getParams, isKozoji } from '../utils/params';
import Kozoji from './Kozoji';
import Main from './Main';

/** /（ルート）パスにひも付くコンポーネント。  */
const Root = () => {
  const location = useLocation();
  /** URLパラメーター。 */
  const params = new URLSearchParams(location.search);
  /** バス停コード。 */
  const busStopCode = getParams(params, 'busStopCode');
  // 高蔵寺案件用のパラメーターが指定されている場合は、高蔵寺用のコンポーネントを表示する。
  if (isKozoji(busStopCode)) {
    return <Kozoji />;
  } else {
    // それ以外（未設定も含む）の場合は、広小路本町・納屋橋用のコンポーネントを表示する。
    // （現状は以下のコンポーネント内でパラメーターチェックが実行されるため、
    //   この階層でパラメーターチェックはしていない。今後この階層でチェックをやっても良いかもしれない？）
    return <Main />;
  }
};

export default Root;
