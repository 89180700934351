import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { AppStateProvider } from './contexts/AppStateContext';
import NotFound from './components/NotFound';
import Root from './components/Root';

function App() {
  return (
    <div className="App">
      <AppStateProvider>
        <BrowserRouter>
          <Routes>
            <Route index path="/" element={<Root />} />
            <Route index path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AppStateProvider>
    </div>
  );
}

export default App;
