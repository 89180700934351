import { Point } from '../../types/mixway-api/extreme';

/**
 * 経路の目的地（Point配列の末尾の要素）の名称を取得する。
 * @param points 経路の地点の配列。
 * @returns 目的地の名称。
 */
export const getDestinationName = (points: Point[]) => {
  const destinationName = points.at(-1)?.Name;
  return destinationName ? destinationName : '';
};
