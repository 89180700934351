import { DateTime } from 'luxon';
import Course from '../Course';
import Header from '../Header';
import Map from './Map';
import KozojiQRCodeArea from './QR';

interface Props {
  /** URLパラメーターに無効なものがあるかを表すフラグ。 */
  hasInvalidParams: boolean;
  /** 出発日時。 */
  depDate: DateTime;
  /** 目的地の緯度経度。 */
  arrivalPosition: string;
}

/** 経路を取得できた場合に表示する部分のコンポーネント。 */
const Result: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Header
        hasInvalidParams={props.hasInvalidParams}
        depDate={props.depDate}
        arrivalPointPosition={props.arrivalPosition}
      />
      <Map />
      <Course />
      <KozojiQRCodeArea arrivalPointPosition={props.arrivalPosition} />
    </>
  );
};

export default Result;
